import { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosInstance from "./AxiosInstance";

export async function post<T>(
  url: string,
  data: T = {} as T,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> {
  return axiosInstance
    .post<T>(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch((e) => e);
}

export async function get<T>(
  url: string,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> {
  return axiosInstance
    .get<T>(url, { ...config })
    .then((response) => response.data)
    .catch((e) => e);
}

export async function put<T>(
  url: string,
  data: T,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> {
  return axiosInstance
    .put<T>(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch((e) => e);
}

export async function del<T>(
  url: string,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> {
  return axiosInstance
    .delete<T>(url, { ...config })
    .then((response) => response.data)
    .catch((e) => e);
}
