import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRoomTypeImages } from "../helper/ApiClient";
import { Gallery } from "react-grid-gallery";
import { Container } from "@mui/material";

const formatImageList = (imageList: string[]) => {
  return imageList?.map((src) => {
    const img = new Image();
    img.src = src;
    return {
      src,
      width: img.width,
      height: img.height,
    };
  });
};

const ImageComponent = (props: any) => {
  return <img {...props.imageProps} alt={`image-${props?.index}`} />;
};

const RoomDesignPage = () => {
  const { type } = useParams<{ type: string }>();
  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    async function fetchImages() {
      try {
        if (type) {
          const response = await getRoomTypeImages(type);

          const formatedImgList = formatImageList(
            response?.map((img: any) => img.gcsUrl)
          );

          setImages(formatedImgList);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    }

    fetchImages();
  }, [type]);

  return (
    <Container
      maxWidth={"lg"}
      disableGutters
      sx={{
        padding: "1rem 0",
      }}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <h1>{type?.toLocaleUpperCase()}</h1>
        <div>
          <Gallery
            images={images}
            thumbnailImageComponent={ImageComponent}
            rowHeight={400}
            enableImageSelection={false}
          />
        </div>
      </div>
    </Container>
  );
};

export default RoomDesignPage;
