import { get } from "./ApiHelper";

export async function getRoomTypes(): Promise<any> {
  const url = `home/images`;
  return get<any>(url);
}
export async function getRoomTypeImages(roomDesign: string): Promise<any> {
  const url = `images/byTag/${roomDesign}`;
  return get<any>(url);
}
