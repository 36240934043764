import { useRoutes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import { ContactUsPage, HomePage, RoomDesignPage, OurVisionPage, NotFoundPage } from "./pages";

const Routes = () => {
  const element = useRoutes([
    {
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/contact-us",
          element: <ContactUsPage />,
        },
        {
          path: "/room-design/:type",
          element: <RoomDesignPage />,
        },
        {
          path: "/our-vision",
          element: <OurVisionPage />,
        },
        {
          path: "*",
          element: <NotFoundPage />,
        },
      ],
    },
  ]);

  return element;
};

export default Routes;
