import React from "react";
import { Box, Typography, Link, Container } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#333", 
        color: "#fff",
        padding: "1rem 0",
        margin: "0 auto",
        textAlign: "center"
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flex: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            flexDirection: { xs: "column"}, 
          }}
        >
        <Typography
          variant="body2"
          sx={{ marginTop: { xs: "1rem", sm: "0" } }}
        >
          &copy; {new Date().getFullYear()} Own House. All rights reserved.
        </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
