import React from "react";
import { Box, Container, colors, Typography } from "@mui/material";

const AppBanner: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundImage: 'url("hero-bg.svg")',
        backgroundRepeat: "repeat-y",
        backgroundSize: "cover",
        backgroundPosition: "left",
        color: "#fff",
        backgroundColor: colors.green[500],
        minHeight: {xs: '40vh', md: '70vh'},
        backgroundPositionX:'center'
      }}
    >
      <Container maxWidth={"md"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "2rem",
            padding: "5rem 1rem",
          }}
          maxWidth={"500"}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: 600,
              fontSize: { xs: "2rem", md: "3rem" },
            }}
          >
            Take your interior design content from drab to fab
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
            }}
          >
            Transforming the way buyers and sellers do business online
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default AppBanner;
