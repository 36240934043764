import HeroBanner from './../components/home/HomeBanner';
import RoomDesigns from './../components/home/RoomDesigns';


const HomePage: React.FC = () => {
    return (
        <>
            <HeroBanner />
            <RoomDesigns />
        </>

    )
}

export default HomePage