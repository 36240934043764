import { Box } from "@mui/material"

const OurVision: React.FC = () => {
    return (
        <Box component="section" sx={{ p: 2, alignItems: "center", height: '79vh' }}>
            <h1>Coming soon....</h1>
        </Box>
    )
}

export default OurVision