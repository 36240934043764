// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoomDesigns_imageHolder__hFvPS {
  cursor: pointer;
  position: relative;
}
.RoomDesigns_imageHolder__hFvPS::after {
  content: "";
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 8;
}
.RoomDesigns_imageHolder__hFvPS img {
  border-radius: 15px;
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.RoomDesigns_imageHolder__hFvPS:hover img {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/components/home/scss/RoomDesigns.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;AACF;AAAE;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,oCAAA;EACA,UAAA;AAEJ;AAAE;EACE,mBAAA;EACA,UAAA;EAEA,4BAAA;AAEJ;AACI;EACE,YAAA;AACN","sourcesContent":[".imageHolder {\n  cursor: pointer;\n  position: relative;\n  &::after {\n    content: \"\";\n    width: 100%;\n    height: 70px;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    background: rgb(255 255 255 / 80%);\n    z-index: 8;\n  }\n  img {\n    border-radius: 15px;\n    opacity: 1;\n    -webkit-transition: 0.3s ease-in-out;\n    transition: 0.3s ease-in-out;\n  }\n  &:hover {\n    img {\n      opacity: 0.5;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageHolder": `RoomDesigns_imageHolder__hFvPS`
};
export default ___CSS_LOADER_EXPORT___;
