import { Box, Button } from "@mui/material"

const NotFound: React.FC = () => {
    return (
        <Box component="section" sx={{ p: 2, alignContent: "center", height: '80vh' }}>
            <h1>Page Not Found</h1>
            <Button href="/" variant="contained">Go Back</Button>
        </Box>
    )
}

export default NotFound